import { HttpContext, HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { App, AppState } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';

export const AUTHENTICATE = new HttpContextToken<boolean>(() => false);
export const AUTHENTICATED = new HttpContext().set(AUTHENTICATE, true);

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor, OnDestroy {
  private stageChangeListener: PluginListenerHandle | null = null;
  private isBackgroundMode = false;

  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.setupAppStateListener();
  }

  private setupAppStateListener(): void {
    if (!this.stageChangeListener) {
      App.addListener('appStateChange', (state: AppState) => {
        this.isBackgroundMode = !state?.isActive;
      }).then(listener => {
        this.stageChangeListener = listener;
      }).catch(error => {
        console.error('Failed to set up app state listener:', error);
      });
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isBackgroundMode) {
      console.error("App is in background Mode. No requests will be processed.");
      return throwError(() => new Error("App is in background Mode. No requests will be processed."));
    }

    if (request.context.get(AUTHENTICATE)) {
      return this.authenticationService.handleAuthenticatedRequest(request, next);
    }

    // Handle request that doesn't require authentication
    return next.handle(request);
  }

  ngOnDestroy(): void {
    // Cleanup event listener to prevent memory leaks
    if (this.stageChangeListener) {
      this.stageChangeListener.remove().catch(error => {
        console.error('Failed to remove app state listener:', error);
      });
      this.stageChangeListener = null;
    }
  }
}
