import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetResult, Preferences } from '@capacitor/preferences';
import { differenceInSeconds } from 'date-fns';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store';
import { getPushEnabled } from 'src/app/ngrx/settings/settings.reducer';
import { PushNotifications } from '@capacitor/push-notifications';
import { PwaPushNotificationPlugin } from 'src/app/pwaPushNotification';
import { Capacitor } from '@capacitor/core';
import { PWAService } from '../pwa.service';

export interface LoginCredentials {
  username: string;
  password: string;
}

export interface OAuthCredentials<T> {
  expires_in: T;
  token_type: string;
  refresh_token: string;
  access_token: string;
  scope: string;
}

export interface OAuthTokenInfo<T> {
  uid: string;
  name: string;
  sn: string;
  givenname: string;
  scope: Array<string>;
  roles: Array<string>;
  realm: string;
  token_type: string;
  expires_in: T;
  access_token: string;
  employeeNumber?: string;
  'x-de-tudortmund-matr'?: string;
}

export enum SignOutType {
  REFRESH_TOKEN_INVALID = 'refresh_token_invalid',
  USER_INITIATED = 'user_initiated',
  APP_RESET = 'app_reset',
  UNKNOWN = 'unknown',
}

const API_URL = environment.apiUrl;
const API_SERVICE = `${API_URL}/oauth2/v2`;
export const API_ACCESS_TOKEN = `${API_SERVICE}/access_token`;
export const API_TOKEN_INFO = `${API_SERVICE}/tokeninfo`;
export const API_LOGOUT = `${API_SERVICE}/logout`;
export const STORAGE_KEY = 'oauth';
export const REFRESH_THRESHOLD = 300; // 5 minutes; value in seconds

interface StorageOAuth {
  oauth_credentials: OAuthCredentials<number | Date> | null;
  token_info: OAuthTokenInfo<number | Date> | null;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
  public oauth: StorageOAuth = { oauth_credentials: null, token_info: null };
  private refresh$ = new BehaviorSubject<Observable<OAuthCredentials<Date> | HttpErrorResponse> | null>(null);
  private pushEnabled$: Observable<boolean>;
  private subscriptions = new Subscription();
  
  private get refresh(): Observable<OAuthCredentials<Date> | HttpErrorResponse> | null {
    return this.refresh$.getValue();
  }
  
  private set refresh(value: Observable<OAuthCredentials<Date> | HttpErrorResponse> | null) {
    this.refresh$.next(value);
  }

  constructor(
    private http: HttpClient,
    private alertController: AlertController,
    private translate: TranslateService,
    private store: Store<AppState>,
  ) {
    this.loadStoredCredentials();
    this.pushEnabled$ = this.store.pipe(select(getPushEnabled));
  }

  private async loadStoredCredentials(): Promise<void> {
    try {
      const value: GetResult = await Preferences.get({ key: STORAGE_KEY });
      if (value.value) {
        const stored = JSON.parse(value.value) as StorageOAuth;
        
        // Ensure dates are properly converted from strings to Date objects
        if (stored.oauth_credentials && typeof stored.oauth_credentials.expires_in === 'string') {
          stored.oauth_credentials.expires_in = new Date(stored.oauth_credentials.expires_in);
        }
        
        if (stored.token_info && typeof stored.token_info.expires_in === 'string') {
          stored.token_info.expires_in = new Date(stored.token_info.expires_in);
        }
        
        this.oauth = stored;
      }
    } catch (error) {
      console.error('Failed to load stored credentials:', error);
      // Reset oauth state if loading fails
      this.oauth = { oauth_credentials: null, token_info: null };
    }
  }

  public isAuthenticated(): boolean {
    return !!this.oauth?.oauth_credentials;
  }

  public expireToken(): void {
    if (!this.oauth.oauth_credentials) {
      return;
    }
    
    this.oauth = {
      ...this.oauth,
      oauth_credentials: {
        ...this.oauth.oauth_credentials,
        expires_in: new Date()
      }
    };
    
    this.saveOAuthState().catch(error => {
      console.error('Failed to save expired token state:', error);
    });
  }

  public isExpired(): boolean {
    if (!this.oauth.oauth_credentials) {
      return true;
    }
    
    const expiresIn = this.oauth.oauth_credentials.expires_in;
    const expiryDate = expiresIn instanceof Date ? expiresIn : new Date(new Date().getTime() + expiresIn * 1000);
    
    return differenceInSeconds(expiryDate, new Date()) < REFRESH_THRESHOLD;
  }

  /**
   * Authenticate with LoginCredentials
   *
   * @param credentials login credentials consisting of username and password
   * @returns Observable<OAuthCredentials>
   */
  public authenticate(credentials: LoginCredentials): Observable<OAuthCredentials<Date> | HttpErrorResponse> {
    const oauthBody = { 
      username: credentials.username, 
      password: credentials.password, 
      grant_type: 'password' 
    };
    
    return this.http
      .post<OAuthCredentials<number>>(API_ACCESS_TOKEN, JSON.stringify(oauthBody), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .pipe(
        map((oauthCredentials: OAuthCredentials<number>) => ({
          ...oauthCredentials,
          expires_in: new Date(new Date().getTime() + oauthCredentials.expires_in * 1000)
        })),
        switchMap((oauthCredentials: OAuthCredentials<Date>) => {
          this.oauth = {
            ...this.oauth,
            oauth_credentials: oauthCredentials
          };
          
          return from(this.saveOAuthState()).pipe(
            switchMap(() => this.registerPushNotificationsIfEnabled()),
            map(() => oauthCredentials),
            catchError(error => {
              console.error('Failed to save authentication state:', error);
              return throwError(() => error);
            })
          );
        })
      );
  }

  private registerPushNotificationsIfEnabled(): Observable<void> {
    return this.pushEnabled$.pipe(
      take(1),
      switchMap(async (enabled: boolean) => {
        if (enabled) {
          const pushImplementation = Capacitor.isNativePlatform() ? 
            PushNotifications : PwaPushNotificationPlugin.getInstance();
            
          try {
            const permissionStatus = await pushImplementation.requestPermissions();
            console.info(`[PushNotification] permission was ${permissionStatus.receive}`);
            
            if (permissionStatus.receive === 'granted') {
              console.info(`[PushNotification] registering...`);
              await pushImplementation.register();
            }
          } catch (error) {
            console.error('[PushNotification] error during registration:', error);
          }
        }
      })
    );
  }

  public handleAuthenticatedRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('[Authentication Interceptor]', 'authentication state', this.isAuthenticated());
    console.log('[Authentication Interceptor]', 'token expiration state', this.isExpired());
    
    // If not authenticated, just pass the request through
    if (!this.isAuthenticated()) {
      return next.handle(request);
    }
    
    // If token is valid, add auth header and send
    if (!this.isExpired()) {
      return next.handle(this.addAuthorizationHeader(
        request, 
        this.oauth.oauth_credentials!.access_token
      ));
    }
    
    // Token is expired, needs refresh
    if (this.refresh === null) {
      this.refresh = this.refreshToken().pipe(
        tap(() => {
          console.log('[Authentication Interceptor]', 'attempting to refresh access token');
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.BadRequest) {
            this.oauth = { oauth_credentials: null, token_info: null };
            return from(this.saveOAuthState()).pipe(
              switchMap(() => this.handleRefreshError(error)),
              switchMap(() => throwError(() => error))
            );
          }
          return throwError(() => error);
        }),
        switchMap((refreshedOAuthCredentials: OAuthCredentials<Date>) => {
          this.oauth = {
            ...this.oauth,
            oauth_credentials: refreshedOAuthCredentials
          };
          
          return from(this.saveOAuthState()).pipe(
            tap(async () => {
              try {
                const stored = await Preferences.get({ key: STORAGE_KEY });
                if (stored.value !== JSON.stringify(this.oauth)) {
                  // Log if stored oauth credentials are different from the actual refreshed credentials
                  this.logCredentialsMismatch();
                }
              } catch (error) {
                console.error('Error checking stored credentials:', error);
              }
            }),
            map(() => refreshedOAuthCredentials)
          );
        }),
        shareReplay(1),
        finalize(() => {
          // Reset refresh observable when completed or on error
          this.refresh = null;
        })
      );
    }
    
    return this.refresh.pipe(
      switchMap(() => {
        // Get the latest access token after refresh
        const accessToken = this.oauth.oauth_credentials?.access_token;
        if (!accessToken) {
          return throwError(() => new Error('Access token not available after refresh'));
        }
        return next.handle(this.addAuthorizationHeader(request, accessToken));
      })
    );
  }

  private async logCredentialsMismatch(): Promise<void> {
    try {
      const [appInfo, deviceInfo] = await Promise.all([
        Capacitor.isNativePlatform() ? App.getInfo() : PWAService.getInfo(), 
        Device.getInfo()
      ]);
      
      const body = new URLSearchParams();
      body.set('type', 'CREDENTIALS_MISMATCH');
      body.set('version', appInfo.version);
      body.set('build', appInfo.build);
      body.set('os', deviceInfo.operatingSystem);
      body.set('os_version', deviceInfo.osVersion);
      body.set('webViewVersion', deviceInfo.webViewVersion);
      
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      this.http.post(`${environment.apiUrl}/logout-statistics`, body, { headers }).subscribe();
    } catch (error) {
      console.error('Failed to log credentials mismatch:', error);
    }
  }

  public refreshToken(): Observable<OAuthCredentials<Date>> {
    if (!this.oauth.oauth_credentials?.refresh_token) {
      return throwError(() => new Error('No refresh token available'));
    }
    
    const body = {
      refresh_token: this.oauth.oauth_credentials.refresh_token,
      grant_type: 'refresh_token',
    };
    
    return this.http
      .post<OAuthCredentials<number>>(API_ACCESS_TOKEN, JSON.stringify(body), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        map((oauthCredentials: OAuthCredentials<number>) => ({
          ...oauthCredentials,
          expires_in: new Date(new Date().getTime() + oauthCredentials.expires_in * 1000)
        })),
        switchMap((oauthCredentials: OAuthCredentials<Date>) => {
          this.oauth = {
            ...this.oauth,
            oauth_credentials: oauthCredentials
          };
          
          return from(this.saveOAuthState()).pipe(
            map(() => oauthCredentials),
            catchError(error => {
              console.error('Failed to save refreshed token:', error);
              return throwError(() => error);
            })
          );
        })
      );
  }

  public tokenInfo(oauthCredentials: OAuthCredentials<any>): Observable<OAuthTokenInfo<Date>> {
    return this.http.get<OAuthTokenInfo<number>>(API_TOKEN_INFO, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oauthCredentials.access_token}`
      }),
    }).pipe(
      map((oauthTokenInfo: OAuthTokenInfo<number>) => ({
        ...oauthTokenInfo,
        expires_in: new Date(new Date().getTime() + oauthTokenInfo.expires_in * 1000)
      })),
      switchMap((tokenInfo: OAuthTokenInfo<Date>) => {
        this.oauth = {
          ...this.oauth,
          token_info: tokenInfo
        };
        
        return from(this.saveOAuthState()).pipe(
          map(() => tokenInfo),
          catchError(error => {
            console.error('Failed to save token info:', error);
            return throwError(() => error);
          })
        );
      })
    );
  }

  public signOut(): Observable<void> {
    // If not authenticated, no need to call the server
    if (!this.isAuthenticated() || !this.oauth.oauth_credentials?.refresh_token) {
      this.oauth = {
        oauth_credentials: null,
        token_info: null
      };
      return from(this.saveOAuthState());
    }
    
    const body = {
      token: this.oauth.oauth_credentials.refresh_token,
    };
    
    return this.http.post<void>(API_LOGOUT, JSON.stringify(body), { 
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }) 
    })
    .pipe(
      catchError(error => {
        console.error('Error during logout:', error);
        return of(null); // Continue with local logout even if server logout fails
      }),
      switchMap(() => {
        this.oauth = {
          oauth_credentials: null,
          token_info: null
        };
        
        return from(this.saveOAuthState()).pipe(
          switchMap(() => this.registerPushNotificationsIfEnabled()),
          map(() => null)
        );
      })
    );
  }

  private addAuthorizationHeader(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  /**
   * Bad Requests (HTTP status code 400) errors that could occur:
   *
   * - "grant (refresh_token) is invalid e.g. refresh_token is expired"
   * - "invalid_scope" error e.g. the scope was extended by the server
   */
  private handleRefreshError(error: HttpErrorResponse | Error): Observable<void> {
    if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.BadRequest) {
      // Collect statistics about the logout behavior
      this.logLogoutStatistics(SignOutType.REFRESH_TOKEN_INVALID);
      
      // Show alert to user
      return from(
        this.alertController.create({
          header: this.translate.instant('auth.signOut.refreshTokenInvalid.title'),
          message: this.translate.instant('auth.signOut.refreshTokenInvalid.message'),
          buttons: [this.translate.instant('core.alert.ok')],
        })
      ).pipe(
        switchMap((alert: HTMLIonAlertElement) => from(alert.present())),
        map(() => null)
      );
    }
    
    return throwError(() => error);
  }

  private async logLogoutStatistics(type: SignOutType): Promise<void> {
    try {
      const [appInfo, deviceInfo] = await Promise.all([
        Capacitor.isNativePlatform() ? App.getInfo() : PWAService.getInfo(), 
        Device.getInfo()
      ]);
      
      const body = new URLSearchParams();
      body.set('type', type);
      body.set('version', appInfo.version);
      body.set('build', appInfo.build);
      body.set('os', deviceInfo.operatingSystem);
      body.set('os_version', deviceInfo.osVersion);
      body.set('webViewVersion', deviceInfo.webViewVersion);
      
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      this.http.post(`${environment.apiUrl}/logout-statistics`, body, { headers }).subscribe();
    } catch (error) {
      console.error('Failed to log logout statistics:', error);
    }
  }

  public getUserRoles(): string[] {
    return this.oauth?.token_info?.roles ?? [];
  }

  private async saveOAuthState(): Promise<void> {
    return Preferences.set({
      key: STORAGE_KEY,
      value: JSON.stringify(this.oauth)
    });
  }

  ngOnDestroy(): void {
    // Clean up all subscriptions when service is destroyed
    this.subscriptions.unsubscribe();
  }
}
